<template>
    <div>
        <span id="show-redeem-modal" style="display: none" data-toggle="modal" data-target="#instantRedeem"></span>
        <RedeemModal id="instantRedeem" @close="onClose">
            <div class="redeem-form">
                <div class="text-light matches-title">
                    <h4>Redeem Bonus</h4>
                    <div class="balance">
                        {{ promptTxt }}
                    </div>
                </div>

                <div class="form-wrapper1">
                    <div v-if="message" class="alert" v-bind:class="{
                        'alert-success': message.type === 'success',
                        'alert-danger': message.type === 'error'
                    }" role="alert">
                        {{ message.message }}
                    </div>
                    <input type="text" class="form-control std-input" name="code" placeholder="Enter bonus" min="0"
                        aria-label="Code" v-model="bonus_code" />
                    <button @click="redeem" v-bind:class="loading" class="btn-std btn-prim btn-large"
                        :disabled="loading">
                        {{ loading ? 'Processing...' : 'REDEEM' }}
                    </button>
                </div>
            </div>
        </RedeemModal>
    </div>
</template>

<script>
import RedeemModal from './Modal.vue';
import bonus from "@/services/bonus";

export default {
    name: 'InstantRedeem',
    data: function () {
        return {
            loading: false,
            amount: 0,
            message: null,
            profile: this.getProfile(),
            promptTxt: '',
            isVisible: false,
        }
    },
    computed: {
        balance() {

            return this.formatCurrency(this.profile?.b1);
        },
    },
    components: {
        RedeemModal,
    },
    methods: {
        onClose() {
            this.amount = null;
            this.message = null;
            this.loading = false;
            this.isVisible = false;
            this.promptTxt = "";
        },
        redeem() {
            this.message = null;
            var p = this.getAuth();
            if (!p) {
                this.$toast.open({
                    message: 'Please login to proceed',
                    type: 'error',
                    position: 'top'
                });
                this.$router.push({ name: "login", params: {} });
                return;
            }

            var vm = this;
            var path = "/campaign/redeem";

            var data = {
                bonus_code: this.bonus_code,
            };

            this.loading = true;

            bonus
                .post(path, JSON.stringify(data), {
                    headers: {
                        "api-key": vm.getAuth(),
                    },
                })
                .then((res) => {
                    var msg = res.data.data;
                    this.message = {
                        message: msg,
                        type: 'success'
                    }

                })
                .catch((err) => {
                    if (err.response) {
                        if (
                            parseInt(err.response.data.error_code) === 401 ||
                            parseInt(err.response.data.error_code) === 400 ||
                            parseInt(err.response.data.error_code) === 428
                        ) {
                            this.$toast.open({
                                message: 'Your session on this device has expired',
                                type: 'error',
                                position: 'top'
                            });
                            // vm.logout();
                            return;
                        } else {
                            this.message = {
                                message: err.response.data.error_message ?? 'Failed',
                                type: 'error'
                            }

                        }
                    } else if (err.request) {
                        this.message = {
                            message: 'Network Error. Check your network connection and try again',
                            type: 'error'
                        }


                    } else {
                        this.message = {
                            message: 'An error occurred. Please try again',
                            type: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading = false

                })
        },
    },
    mounted: function () {
        this.EventBus.$emit('redeem:popup', {
            message: "Redeem init",
        });
    },
    created: function () {
        var vm = this;
        this.EventBus.$on('redeem:popup', function (payload) {

            vm.amount = payload.amount;

            if (payload.message !== null && payload.message.length > 0) {

                vm.promptTxt = payload.message;

            } else {

                vm.promptTxt = "Balance " + vm.formatCurrency(vm.profile?.b1);

            }

            document.getElementById("show-redeem-modal").click();

            vm.isVisible = true;

        });

    },

}
</script>

<style scoped>
.redeem-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
    z-index: 1500;
}

.form-wrapper1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-wrapper1 input {
    background: var(--primary-bg-light);
    color: white;
}

.alert {
    position: relative;
    border-radius: 0.5rem;

}

h4 {
    margin: 0;
    margin-bottom: 0.5rem;
}

.redeem-btns-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.redeem-btns-grid .odd-btn {
    padding: 0.2rem;
    font-size: 0.8rem;
    background: var(--primary-bg-light);
    color: white;
    border: none;
    border-radius: 0.5rem;
    width: auto;
}

.tabs-list {
    width: 100%;
}

.tabs-list ul {
    display: flex;
    justify-content: center;
    list-style: none;
    justify-content: flex-start;
    padding: 0;
    gap: 1rem;
    border-bottom: 1px var(--primary-bg-light) solid;
}

.tabs-list ul li {
    cursor: pointer;
    border-bottom: 1px solid transparent;
    padding: 10px 0;
    margin-bottom: -1px;
}

.tabs-list ul li.active {
    border-color: var(--yellow);
    color: var(--yellow);
    font-weight: bold;
}

.balance {
    background: #000C15;
    color: var(--yellow);
    border-radius: 0.3rem;
    padding: 0.2rem 0.5rem;
    display: table;
    font-size: 0.8rem;
}
</style>