<template>
  <div class="bottom-fixed-bar" ref="bottomBar">
    <slot></slot>
    <div class="bottom-navigation">
      <a class="nav-item" @click="goTo('sport')">
        <img src="/img/BottomNav/home.png" class="menu-icon-svg">
        <span class="item-name">Home</span>
      </a>

      <router-link to="/live" class="nav-item">
        <div class="icon">
          <img src="/img/BottomNav/PlayButton.svg" class="menu-icon-svg">
          <span class="custom-badge">{{ liveGames }}</span>
        </div>
        <span class="item-name">Live</span>
      </router-link>

      <a v-if="betslip_count > 0" data-toggle="modal" data-target="#showBetSlip" class="nav-item">
        <div class="icon" style="position: relative;">
          <span class="betslip-custom-badge">{{ betslip_count }}</span>
        </div>
      </a>
      <RedeemBonus v-else />

      <router-link to="/my-profile-bets" class="nav-item">
        <div class="icon">
          <img src="/img/BottomNav/Ticket.svg" class="menu-icon-svg">
          <span class="custom-badge">{{ normalBetsCount }}</span>
        </div>
        <span class="item-name">My Bets</span>
      </router-link>

      <router-link v-show="isLoggedIn" to="/my-account" class="nav-item">
        <img src="/img/BottomNav/User.svg" class="menu-icon-svg">
        <span class="item-name">Account</span>
      </router-link>

      <router-link v-show="!isLoggedIn" to="/login" class="nav-item">
        <img src="/img/BottomNav/User.svg" class="menu-icon-svg">
        <span class="item-name">Login</span>
      </router-link>
    </div>
    <InstantRedeem v-if="showModal" @close="showModal = false" />
  </div>
</template>

<script>
import Vue from 'vue';
Vue.prototype.EventBus = new Vue();
import InstantRedeem from '@/components/redeem/InstantRedeem';
import RedeemBonus from "./settings/RedeemBonusButton.vue";

export default {
  name: 'BottomNavigationMenu',
  props: ['isLoggedIn', 'normalBetsCount', 'betslip_count', 'liveGames'],
  data() {
    return {
      showModal: false,
    };
  },
  mounted() {
    const observer = new ResizeObserver(this.getBottomBarHeight);
    observer.observe(this.$refs.bottomBar);
    this.getBottomBarHeight();
  },
  components: {
    InstantRedeem,
    RedeemBonus,
  },
  beforeDestroy() {
    console.log('unmounted');
    document.documentElement.style.setProperty('--bottom-bar-height', '0px');
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    showRedeem() {
      console.log('showRedeem clicked');
      this.showModal = true; // Set showModal to true when the user explicitly clicks to show the modal
    },
    getBottomBarHeight() {
      const height = this.$refs.bottomBar.clientHeight;
      document.documentElement.style.setProperty('--bottom-bar-height', `${height + 20}px`);
    },
  }
}
</script>

<style scoped>
.betslip-custom-badge {
  background-color: var(--yellow);
  color: var(--darkBackground);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  top: -30px;
  right: -30px;
  z-index: 1;
}

.bottom-fixed-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  max-width: 568px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.bottom-navigation {
  background-color: var(--primary-dark);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid var(--primary-bg-light);
}

.custom-badge {
  position: absolute;
  right: -10px;
  bottom: -5px;
  padding: 1px;
  font-size: 13px;
  border-radius: 50%;
  display: inline-block;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 12px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-item {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #fff;
  line-height: 1;
  gap: 0.7rem;
}

.nav-item .icon {
  position: relative;
}

.nav-item img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  object-position: center;
}

.nav-item>span {
  font-size: 14px;
}
</style>