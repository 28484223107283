<template>
  <div style="" class="slip-list">
    <section class="d-flex justify-content-left game-card">

      <div class="content-section">

        <!-- <router-link
          :to="{ name: 'match', params: { game_id: game_id === undefined ? 0 : game_id, name: toURL(name), status: live ? 'live' : 'prematch' } }"> -->
          <div class="row m-0 p-0">
            <div class="col-9 m-0 p-0">
              <div style="font-weight: bold;" class="teams text-dark">
                {{ bet.competitor_1 }} v {{ bet.competitor_2 }} <span v-if="bet.live" class="text-danger">Live</span>
              </div>
            </div>
            <div style="font-weight: bold;" class="col-3 m-0 p-0 text-right odds-tag">
              <span class="odds-bts text-dark">{{ bet.odds | formatOdds }}</span>
            </div>
          </div>

          <div class="row m-0 p-0">

            <div class="col-4 m-0 p-0">
              <div style="font-weight: bold;" class="small-text text-dark op-low">
                {{ bet.market_name }}
              </div>
            </div>

            <div class="col-8 m-0 p-0 pick text-dark small-text">
              <div style="font-weight: bold;" class="pick text-dark small-text p-0 text-right op-low">
                Pick: <span class="text-dark">{{ bet.outcome_name }}</span>
              </div>
            </div>

          </div>
        <!-- </router-link> -->
      </div>
      <a class="custom-link">
        <div class="py-2 px-3 text-dark remove-match-betslip" @click="removePick(bet.match_id)">
          x
        </div>
      </a>
    </section>
  </div>

</template>

<script>

export default {
  name: "Pick",
  props: {
    bet: {
      required: true
    },
    game_id: {
      type: Number, // or String depending on your data
      required: true
    }
  },
  methods: {
    closeBetslip() {
      document.getElementById("showBetSlip").style.display = "none";
    },

    init: function () {

      var vm = this;

      vm.previousOdds = this.bet.odds;

      vm.id = this.getOddID(this.bet.match_id, this.bet.market_id, this.bet.outcome_id, this.bet.specifier, 'odd');
      this.EventBus.$on('odds:' + vm.id, function (payload) {

        var hasChanged = false;

        if (parseFloat(payload.odds) !== parseFloat(vm.bet.odds)) {

          hasChanged = true;
        }

        if (parseInt(vm.bet.active) !== parseInt(payload.active)) {

          hasChanged = true;
        }

        if (parseInt(vm.bet.status) !== parseInt(payload.status)) {

          hasChanged = true;
        }

        if (hasChanged) {

          console.log(JSON.stringify(payload, undefined, 4));

          vm.bet.status = payload.status;
          vm.bet.active = payload.active;

          var oddsChangedPayload = {};

          oddsChangedPayload.id = vm.id;
          oddsChangedPayload.odds = payload.odds;
          oddsChangedPayload.status = payload.status;
          oddsChangedPayload.active = payload.active;
          oddsChangedPayload.previous_odds = payload.odds;

          console.log('current ' + payload.odds + ' previous ' + vm.bet.odds);

          if (parseFloat(payload.odd) !== parseFloat(vm.bet.odds)) {

            vm.previousOdds = vm.bet.odds;
            vm.bet.odds = payload.odds;

            // odds changed
            if (parseFloat(payload.odds) > parseFloat(vm.previousOdds)) {

              var direction = 1

            } else {

              direction = 0

            }

            console.log('direction ' + direction);

            var directionTxt = parseInt(direction) === 1 ? 'odds has gone up ' : 'odds has gone down ';
            directionTxt = directionTxt + ' from ' + vm.formatOdds(vm.previousOdds) + ' to ' + vm.bet.odd;
            vm.bet.odds_change_text = directionTxt;
            oddsChangedPayload.previous_odds = vm.previousOdds;
            console.log('directionTxt ' + directionTxt);

          }

          vm.EventBus.$emit('odds:changed', oddsChangedPayload);

        }

      });

      this.EventBus.$on('producer:status', function (payload) {
        vm.bet.producer_status = payload.producer_status;
      });

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    },
    getSportAlt: function (sport_id) {

      switch (parseInt(sport_id)) {

        case 1:
          return "Soccer Icon";

        case 2:
          return "basketball icon";

        case 4:
          return "hockey icon";

        case 5:
          return "tennis icon";

        case 22:
          return "/assets/icons/dart.svg";

        case 20:
          return "/assets/icons/tabletennis.svg";

        case 6:
          return "/assets/icons/handball.svg";

        case 12:
          return "/assets/icons/rugby.svg";

        case 10:
          return "/assets/icons/boxing.svg";

        case 31:
          return "/assets/icons/badminton.svg";

        case 3:
          return "/assets/icons/baseball.svg";
      }

      return ""
    },
  },
  filters: {

    currency: function (amount) {

      if (amount == null) {

        amount = 0;
      }

      return (amount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'KES',
      });

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    }
  },
  mounted: function () {

    // this.init();
    // this.name = `${this.bet.competitor_1}-vs-${this.bet.competitor_2}`;

    this.init();
    this.name = `${this.bet.competitor_1}-vs-${this.bet.competitor_2}`;

    // Log the game_id
    console.log('Game ID:', this.game_id);

  },
  data: function () {

    return {
      id: '',
      statusClass: '',
      previousOdds: '',
      oddActive: '',
      producerStatus: '',
      locked: false,
      formattedFixture: '',
    }
  },
  watch: {
    bet: function () {
      this.init();
    }
  },
  computed: {
    directionText() {
      return this.odds_change_text;
    },
    currentFixture: {
      get() {
        return this.formattedFixture;
      },
      set(newValue) {
        this.formattedFixture = newValue;
      }
    },
  }
}
</script>

<style scoped>
.odds-bts {
  background: var(--light-gray);
  padding: 0.1rem 0.2rem;
  border-radius: 9999px;
  display: inline-block;
}

.content-section {
  flex-grow: 1;
}
</style>