<template>
  <div>
    <MenuItem data-toggle="modal" data-target="#showRedeemBonusButton">
    <div class="icon" style="position: relative;">
      <span class="betslip-custom-badge">0</span>
    </div>
    </MenuItem>
    <div ref="redeemModal" class="modal redeem-modal fade" id="showRedeemBonusButton" tabindex="-1"
      aria-labelledby="showRedeemBonusButton" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered"> <!-- Use modal-dialog-centered class -->
        <div class="modal-content">
          <div class="modal-body">
            <div class="wallet-modal">
              <div class="md-title">
                <h4 class="text-light">Redeem your bonus</h4>
              </div>
              <section class="form">
                <div v-if="message" class="alert" v-bind:class="{
                  'alert-success': message.type === 'success',
                  'alert-danger': message.type === 'error'
                }" role="alert">
                  {{ message.message }}
                </div>
                <input type="text" class="form-control std-input" name="code" placeholder="Enter bonus" min="0"
                  aria-label="Code" v-model="bonus_code" />
                <button @click="redeem" v-bind:class="loading" class="btn-std btn-prim btn-large" :disabled="loading">
                  {{ loading ? 'Processing...' : 'REDEEM' }}
                </button>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bonus from "@/services/bonus";
import MenuItem from "./MenuItemButton.vue";

export default {
  name: "SortDeposit",
  data: () => ({
    bonus_code: null,
    loading: false,
    message: null,
  }),
  components: {
    MenuItem,
  },
  mounted() {
    window.$(this.$refs.redeemModal).on("hidden.bs.modal", this.reset);
  },

  methods: {
    reset() {
      this.bonus_code = null;
      this.message = null;
      this.loading = false;
    },

    redeem() {
      this.message = null;
      var p = this.getAuth();
      if (!p) {
        this.$toast.open({
          message: "Please login to proceed",
          type: "error",
          position: "top",
        });
        this.$router.push({ name: "login", params: {} });
        return;
      }

      var vm = this;
      var path = "/campaign/redeem";

      var data = {
        bonus_code: this.bonus_code,
      };

      this.loading = true;

      bonus
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          var msg = res.data.data;
          this.message = {
            message: msg,
            type: "success",
          };
        })
        .catch((err) => {
          if (err.response) {
            if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 400 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              this.$toast.open({
                message: "Your session on this device has expired",
                type: "error",
                position: "top",
              });
              return;
            } else {
              this.message = {
                message: err.response.data.error_message ?? "Failed",
                type: "error",
              };
            }
          } else if (err.request) {
            this.message = {
              message:
                "Network Error. Check your network connection and try again",
              type: "error",
            };
          } else {
            this.message = {
              message: "An error occurred. Please try again",
              type: "error",
            };
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.betslip-custom-badge {
  background-color: var(--yellow);
  color: var(--darkBackground);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  top: -30px;
  right: -30px;
  z-index: 1;
}

.form,
.wallet-modal {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.redeem-modal {
  background: rgba(0, 0, 0, 0.5);
  z-index: 500;
}

.alert {
  position: relative;
}

.wallet-modal {
  background: var(--primary);
  padding: 1rem;
  border-radius: 0.5rem;
}

.modal-dialog {
  display: flex;
  justify-content: center;
}

.modal-dialog-centered {
  /* Centers the modal vertically and horizontally */
  display: flex;
  align-items: center;
  min-height: 100vh;
}

.modal-content {
  background: none;
}

.md-title {
  display: flex;
  flex-direction: column;
}

.md-title h4 {
  margin: 0;
}

.md-title span {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.5);
}
</style>
